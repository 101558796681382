import React from 'react';
import flower from '../images/about-page/mainFlowerArrangement.jpg';

export default function HomeOpening() {
    return (
        <section className="home-opening mb-4 text-center">
            <img
                src={flower}
                className="img-fluid home-opening-image"
                alt="Main Flower Arrangement by Field Day Florals"
            />
            <h1 className="centered-title">Field Day Florals</h1>
        </section>
    );
}
