import React from 'react';
import invertedLogo from '../images/logos/fieldDayLogoInverted.png';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <img src={invertedLogo} className="logoPicture" alt="Inverted Logo" />
                <div className="footer-links">
                    <Link to="/about" className="footerLink">About</Link>
                    <Link to="/services" className="footerLink">Services</Link>
                    <Link to="/portfolio" className="footerLink">Portfolio</Link>
                    <Link to="/contact" className="footerLink">Contact</Link>
                </div>
            </div>
        </footer>
    );
}