import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Component to scroll to the top of the page on route change.
 */
export default function GoToTop() {
  const routePath = useLocation();

  useEffect(() => {
    // Scrolls to the top of the page
    window.scrollTo(0, 0);
  }, [routePath]); // Dependency array includes routePath to trigger effect on route change

  return null; // This component does not render anything
}
