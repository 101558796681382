import React from 'react'
import WelcomeImage from '../images/about-page/IMG_7076.jpg'
import WeddingMegan from '../images/about-page/WithMegan.png'
import MakingArrangement from '../images/about-page/girlsOnTheRun.jpg'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useNavigate } from 'react-router-dom';
import '../index.css';

export default function AboutApril() {

    const navigate = useNavigate();
    const handleClick = () => navigate('/contact');

    return (
        <div className="container mt-4 mb-5">
            <header className="row text-center mb-4">
                <div className="row text-center mb-4">
                    <div className="col-md-6 d-flex aligns-items-center justify-content-center flex-column mb-4">
                        <div>
                            <div><img src={WelcomeImage} className="aboutHomeImageProfile"
                                      alt="April, arranging florals in San Francisco, CA"/>
                            </div>
                            <h1 className="text-center aboutHomeHeading">Hi, I'm April!</h1>
                            <p><em>Founder & Owner of Field Day Florals</em></p>
                            <p className="text-center text-paragraph">Field Day Florals is a blend of things I love:
                                flowers, celebrations & community. I’ve always been a flower lover, and when faced with
                                lots of free time during the pandemic, I knew what hobby I wanted to pursue. I quickly
                                found myself devouring tutorials and experimenting with different styles. </p>
                            <p className="text-center text-paragraph">Balancing my growing hobby with my EdTech career, I developed my business and design
                                skills by collaborating with local florists. This journey eventually led me to follow my
                                dream of working with flowers full time and I launched my small business—Field Day
                                Florals</p>
                            <p className="text-center text-paragraph">I'm committed to sustainability and supporting local farmers. I feel grateful to create
                                art with flowers, work with Bay Area businesses, and connect with folks in my
                                community.</p>
                            <p className="text-center text-paragraph">When I’m not working with flowers, you can find me exploring new parts of the Bay Area,
                                visiting local cafes and fun cocktail spots. Some of my favorite things include
                                chocolate old-fashion donuts, Mexican food, thrift shopping, beach vacations and singing
                                along to musicals!</p>
                            <button className="aboutButton" onClick={handleClick} aria-label={"Contact April"}>Contact
                                Me!
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <img src={WeddingMegan} className="aboutHomeImage center-block picOneAbout"
                                 alt="April at a wedding ceremony"/>
                            <div className="picTwoAbout"><img src={MakingArrangement}
                                                              className="aboutHomeImage center-block"
                                                              alt="April during an arrangement workshop"/>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}