import './index.css';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage'
import AboutPage from './pages/AboutPage'
import ServicesPage from './pages/ServicesPage'
import ContactPage from './pages/ContactPage'
import PortfolioPage from './pages/PortfolioPage'
import BlogPage from './pages/BlogPage'
//import ValentinesPage from "./pages/ValentinesPage";
import Footer from './components/Footer';
import {photos} from './PhotoCollageData'
// import {photos, setting} from './PhotoCollageData'
import portfolioPhotos from './PortfolioCollageData'
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import MothersDayPage from "./pages/MothersDayPage";


function App() {
  return (
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path = "/" element = {<HomePage photos = {photos}/>}/>
        <Route path = "/about" element = {<AboutPage/>}/>
        <Route path = "/services" element = {<ServicesPage/>}/>
        <Route path = "/portfolio" element = {<PortfolioPage portfolioPhotos = {portfolioPhotos}/>}/>
        <Route path = "/contact" element = {<ContactPage/>}/>
        <Route path = "/blog" element = {<BlogPage/>}/>
        {/*<Route path = "/valentines" element = {<ValentinesPage photos = {photos} setting = {setting}/>}/>*/}
        {/*<Route path = "/mothersday" element = {<MothersDayPage photos = {photos} setting = {setting}/>}/>*/}
      </Routes> 
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
