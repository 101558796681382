import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function ContactMe() {
    const form = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};

        if (!form.current.user_name.value.trim()) {
            newErrors.user_name = 'Name is required';
        }

        if (!form.current.user_email.value.trim()) {
            newErrors.user_email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(form.current.user_email.value.trim())) {
            newErrors.user_email = 'Email is invalid';
        }

        if (!form.current.subject.value.trim()) {
            newErrors.subject = 'Subject is required';
        }

        if (!form.current.message.value.trim()) {
            newErrors.message = 'Message is required';
        }

        if (!form.current.referral_source.value.trim()) {
            newErrors.referral_source = 'Referral source is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const sendEmail = (e) => {
        e.preventDefault();

        if (validateForm()) {
            setIsSubmitting(true);

            emailjs.sendForm('service_ab844fp', 'template_goxe50a', form.current, '_SImmCTWEBPBz4gSR')
                .then((result) => {
                    console.log("Email sent successfully:", result.text);
                    setIsSubmitted(true);
                    form.current.reset();
                }, (error) => {
                    console.error("Email sending error:", error.text);
                    alert("Failed to send the message, please try again.");
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }
    };

    return (
        <section id="contact" className="bt-5 formFont contactForm">
            <h1 className="text-center contactFormHeading">Let's Work Together!</h1>
            <p className="text-center text-paragraph">
                Need flowers for an event, curious about a workshop, or just wanna chat flowers? Please fill out the form below, and we'll get back to you in two business days.
            </p>
            {isSubmitted ? (
                <div className="alert alert-success" role="alert">
                    Your message has been sent!
                </div>
            ) : (
                <form ref={form} onSubmit={sendEmail} className="formDiv">
                    <div className="form-group">
                        <label htmlFor="user_name">Name</label>
                        <input
                            type="text"
                            id="user_name"
                            className={`form-control mb-2 ${errors.user_name ? 'is-invalid' : ''}`}
                            placeholder="Name"
                            name="user_name"
                            aria-invalid={errors.user_name ? 'true' : 'false'}
                            aria-describedby="user_name_error"
                        />
                        {errors.user_name && (
                            <div id="user_name_error" className="invalid-feedback">
                                {errors.user_name}
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="user_email">Email</label>
                        <input
                            type="email"
                            id="user_email"
                            className={`form-control mb-2 ${errors.user_email ? 'is-invalid' : ''}`}
                            placeholder="Email"
                            name="user_email"
                            aria-invalid={errors.user_email ? 'true' : 'false'}
                            aria-describedby="user_email_error"
                        />
                        {errors.user_email && (
                            <div id="user_email_error" className="invalid-feedback">
                                {errors.user_email}
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="subject">Subject</label>
                        <input
                            type="text"
                            id="subject"
                            className={`form-control mb-2 ${errors.subject ? 'is-invalid' : ''}`}
                            placeholder="Subject"
                            name="subject"
                            aria-invalid={errors.subject ? 'true' : 'false'}
                            aria-describedby="subject_error"
                        />
                        {errors.subject && (
                            <div id="subject_error" className="invalid-feedback">
                                {errors.subject}
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            className={`form-control ${errors.message ? 'is-invalid' : ''}`}
                            placeholder="Let me know all the deets!"
                            name="message"
                            rows="7"
                            aria-invalid={errors.message ? 'true' : 'false'}
                            aria-describedby="message_error"
                        ></textarea>
                        {errors.message && (
                            <div id="message_error" className="invalid-feedback">
                                {errors.message}
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="referral_source">How did you hear about me?</label>
                        <input
                            type="text"
                            id="referral_source"
                            className={`form-control mb-2 ${errors.referral_source ? 'is-invalid' : ''}`}
                            placeholder="Let us know!"
                            name="referral_source"
                            aria-invalid={errors.referral_source ? 'true' : 'false'}
                            aria-describedby="referral_source_error"
                        />
                        {errors.referral_source && (
                            <div id="referral_source_error" className="invalid-feedback">
                                {errors.referral_source}
                            </div>
                        )}
                    </div>
                    <button
                        type="submit"
                        className="btn btn-outline-secondary submit-btn"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Sending...' : 'Send Message'}
                    </button>
                </form>
            )}
        </section>
    );
}