import ServicesInformation from '../components/ServicesInformation'
import GoToTop from "../components/GoToTop";
import { useNavigate } from 'react-router-dom';
import Testimonial from "../components/Testimonials";
import workshopTestimonial from "../images/testimonials/workshop.png";

export default function ServicesPage() {

    const navigate = useNavigate();
    const handleClick = () => navigate('/contact');

    return(
        <div>
            <ServicesInformation/>
            <div className = "d-flex justify-content-center align-items-center">
                <button className = "aboutButton" onClick = {handleClick}>Contact Me!</button>  
            </div>
            <Testimonial
                image={workshopTestimonial}
                altText="Client's Testimonial"
                testimonialText="I wanted to spend my birthday doing something I’ve always wanted to learn — flower arranging! April is knowledgeable, accommodating and such a warm person to work with and be around. My friends and I loved her workshop. She gave clear instructions, helpful tips and walked us through a stunning flower arrangement! Everyone had a blast and left feeling inspired with a new skill set under their belt."
                author="Julianna"
                highlight="April is knowledgeable, accommodating and such a warm person to work with and be around. My friends and I loved her workshop."
            />
            <GoToTop/>
        </div>
    )
}