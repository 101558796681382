export default function Testimonial({ image, altText, testimonialText, author, highlight }) {
    // Function to highlight a part of text
    const renderHighlightedText = () => {
        if (!highlight) {
            return testimonialText;
        }

        const startIndex = testimonialText.indexOf(highlight);
        if (startIndex === -1) {
            return testimonialText;
        }

        const endIndex = startIndex + highlight.length;
        return (
            <>
                {testimonialText.substring(0, startIndex)}
                <span className="testimonialHighlights">{testimonialText.substring(startIndex, endIndex)}</span>
                {testimonialText.substring(endIndex)}
            </>
        );
    };

    return(
        <div className='d-flex justify-content-center align-items-center p-5 container'>
            <div className='row'>
                <div className='col-12 col-md-4 text-center'>
                    <img src={image} className='testimonialImage' alt={altText || "Testimonial"}/>
                </div>
                <div className='p-4 col-12 col-md-8'>
                    <blockquote className='testimonialFont'>
                        "{renderHighlightedText()}"
                        <span> - {author}</span>
                    </blockquote>
                </div>
            </div>
        </div>
    )
}