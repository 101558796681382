import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Blogs = () => {
    //const [blogPosts, setBlogPosts] = useState([]);
    const [blogPosts] = useState([]);

    useEffect(() => {
        // Load blog posts from the directory
        // const loadBlogPosts = async () => {
        //     // Implementation for loading blog posts
        // };

        // loadBlogPosts();
    }, []);

    return (
        <div>
            <h1>Field Day Florals Blog</h1>
            {blogPosts.map((post, index) => (
                <div key={index}>
                    <h2>{post.title}</h2>
                    <p>Author: {post.author}</p>
                    <p>Date: {post.date}</p>
                    <div dangerouslySetInnerHTML={{ __html: post.content }} />
                </div>
            ))}
            <Link to="/create-blog-post">Create New Blog Post</Link>
        </div>
    );
};

export default Blogs;